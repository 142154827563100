<template>
  <ion-page>
    <Main>
      <template #subHeader>
        <Head></Head>
        <ion-row class="tabs-container">
          <ion-col @click="reload('new')">
            <span class="tab" :class="{ active: controlers.tab == 'new' }">
              Ordenes Solicitadas</span
            >
          </ion-col>
          <ion-col @click="reload('done')">
            <span class="tab" :class="{ active: controlers.tab != 'new' }">
              Ordenes Terminadas</span
            >
          </ion-col>
        </ion-row>
      </template>
      <template #content>
        <template v-for="(order, o) in orders" :key="o">
          <Order :order="order"></Order>
        </template>
      </template>
    </Main>
  </ion-page>
</template>

<script>
import { IonPage, IonRow, IonCol, alertController } from "@ionic/vue";
import Main from "@/components/system/main.vue";
import Head from "@/views/system/rotomolde/head.vue";
import Order from "@/views/system/rotomolde/order.vue";
import { ref } from "@vue/reactivity";
import useApi from "@/use/useApi";
export default {
  name: "Orders",
  components: {
    Main,
    Head,
    Order,
    IonPage,
    IonRow,
    IonCol,
  },
  setup() {
    const info = ref({});
    const controlers = ref({
      tab: "new",
    });
    const orders = ref([]);
    const { post } = useApi();
    async function getOrders() {
      const data = await post(
        "app/rotomolde/get-all",
        { done: controlers.value.tab == "new" ? 0 : 1 },
        "Obteniendo información"
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          orders.value = data.orders ? data.orders : [];
        }else{
          orders.value = [];
        }
      }
    }
    function reload(tab) {
      controlers.value.tab = tab;
      getOrders();
    }
    getOrders();
    return { info, orders, controlers, reload };
  },
};
</script>

<style>
.tabs-container {
  background: var(--ion-color-light);
}
</style>
