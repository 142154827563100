<template>
  <ion-row>
    <ion-col>
      <ion-card @click="select">
        <ion-card-header>
          <ion-row>
            <ion-col>
              <ion-card-title>
                Orden de Producción {{ $filters.folio(order.id) }}
              </ion-card-title>
              <ion-card-subtitle>
                <p class="subtitle mt-1">
                  Fecha de Solicitud:
                  <span class="ion-primary-color"> {{ $filters.timeFrom(order.date) }}</span>
                </p>
              </ion-card-subtitle>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <span class="status" :class="{'solicitado': order.status == 'solicitado', 'produccion': order.status != 'solicitado'}">{{ $filters.capitalize(order.status) }}</span>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="tab-head">
            <ion-col size="6">
              <p>Solicitado</p>
            </ion-col>
            <ion-col size="6">
              <p>
                Fecha:
                <span class="ion-primary-color pull-right">
                  {{moment(order.d_date).format('DD MMM YYYY')}}
                </span>
              </p>
            </ion-col>
          </ion-row>
          <ion-row v-for="(product, p) in order.products" :key="p" class="tab-row">
            <ion-col size="6">
              <p><span class="number"> {{product.quantity}} </span> {{product.name.replace(product.capacity, '').replace('Lts.', '')}} </p>
            </ion-col>
            <ion-col size="6">
              <p class="p4">{{product.capacity}} Lts.</p>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</template>

<script>
import useOrder from "@/use/useOrder";
import { useRouter } from "vue-router";
import moment from "moment-business-days";
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/vue";
import { toRefs } from "@vue/reactivity";
export default {
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
  },
  props: {
    order: Object,
  },
  setup(props) {
    const router = useRouter();
    const {setOrder} = useOrder();
    const { order } = toRefs(props);
    function select(){
      setOrder(order.value);
      router.push('/rotomolde/orders/'+order.value.id+'/');
    }
    return {moment, select};
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
</style>
